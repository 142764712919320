import { jwtDecode } from "jwt-decode"
import type { FirebaseAuthToken } from "~~/stores/auth.store"

export default defineNuxtRouteMiddleware(async () => {
  const token = useCookie("auth_token")
  if (!token.value) {
    return abortNavigation()
  }

  const decodedToken = jwtDecode<FirebaseAuthToken>(token.value)

  if (!decodedToken.claims?.isAdmin) {
    return abortNavigation()
  }
})
